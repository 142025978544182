import { Link } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';

import { URLConversion } from '../components/Constants';
import { Item } from '../components/Game/Scoreboard';
import Logo from '../components/Logo';

const AnswersIndex = ({
	pageContext,
}: {
	pageContext: { questions: [{ averageScore: number; query: string }] };
}) => (
	<>
		<Helmet>
			<title>Google Feud - Answers</title>
		</Helmet>
		<Logo />
		<Item
			scoreName={`Answers for ${new Date().toLocaleDateString()}`}
			scoreValue={
				<ul>
					{pageContext.questions.sort().map((question) => {
						const averageScore =
							typeof question.averageScore === 'undefined'
								? 0
								: question.averageScore;
						return (
							<li key={question.query} className="altList">
								<Link to={URLConversion(question.query)}>
									{`${question.query} (${Math.floor(
										averageScore
									).toLocaleString()})`}
								</Link>
							</li>
						);
					})}
				</ul>
			}
		/>
	</>
);
export default AnswersIndex;
