import React from 'react';
export const failXTime = 1500;
export const numberOfAnswers = 10;
export const numberOfGuesses = 4;
export const numberOfRounds = 3;
export const easyThreshold = 17500;
export const numberOfEasyReveals = 2;
export const enterCheck = (
	event:
		| React.MouseEvent<HTMLInputElement | HTMLButtonElement>
		| React.KeyboardEvent<HTMLButtonElement | HTMLInputElement>
		| null
) => event?.type === 'keydown' && 'key' in event && event.key === 'Enter';

export const logEvent = (
	// eslint-disable-next-line no-undef
	Gtag: Gtag.Gtag,
	eventName: string,
	// eslint-disable-next-line no-undef
	eventParams: Gtag.CustomParams
) => {
	if (typeof window !== 'undefined' && typeof gtag !== 'undefined') {
		Gtag('event', eventName, eventParams);
	}
};
